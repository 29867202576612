var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - List Organisations" }
      }),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("Back to dashboard")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Organisations")
                  ]),
                  _c(
                    "gov-grid-row",
                    [
                      _c(
                        "gov-grid-column",
                        { attrs: { width: "two-thirds" } },
                        [
                          _c(
                            "ck-table-filters",
                            {
                              attrs: { "hide-extra": "" },
                              on: { search: _vm.onSearch }
                            },
                            [
                              _c(
                                "gov-form-group",
                                [
                                  _c(
                                    "gov-label",
                                    { attrs: { for: "filter[name]" } },
                                    [_vm._v("Organisation name")]
                                  ),
                                  _c("gov-input", {
                                    attrs: {
                                      id: "filter[name]",
                                      name: "filter[name]",
                                      type: "search"
                                    },
                                    model: {
                                      value: _vm.filters.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "name", $$v)
                                      },
                                      expression: "filters.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.auth.canAdd("organisation")
                        ? _c(
                            "gov-grid-column",
                            { attrs: { width: "one-third" } },
                            [
                              _c(
                                "gov-button",
                                {
                                  attrs: {
                                    type: "submit",
                                    success: "",
                                    expand: ""
                                  },
                                  on: { click: _vm.onAddOrganisation }
                                },
                                [_vm._v("Add organisation")]
                              ),
                              _vm.auth.canImport("organisations")
                                ? _c(
                                    "gov-button",
                                    {
                                      attrs: {
                                        to: { name: "organisations-import" },
                                        type: "submit",
                                        success: "",
                                        expand: ""
                                      }
                                    },
                                    [_vm._v("Bulk import")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("ck-resource-listing-table", {
                    ref: "organisationsTable",
                    attrs: {
                      uri: "/organisations",
                      params: _vm.params,
                      "default-sort": "name",
                      columns: [
                        {
                          heading: "Organisation name",
                          sort: "name",
                          render: function(organisation) {
                            return (
                              organisation.name +
                              " " +
                              this$1.hasUpdateRequest(organisation)
                            )
                          }
                        },
                        {
                          heading: "Web address URL",
                          render: function(organisation) {
                            return organisation.url
                          }
                        },
                        {
                          heading: "Phone number",
                          render: function(organisation) {
                            return organisation.phone || "-"
                          }
                        },
                        {
                          heading: "Email",
                          render: function(organisation) {
                            return organisation.email || "-"
                          }
                        }
                      ],
                      "view-route": function(organisation) {
                        return {
                          name: "organisations-show",
                          params: { organisation: organisation.id }
                        }
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }